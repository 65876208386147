import intlService from "../services/intl";
import NameMap from "./nameMap";

export type Currency = {
  type: string;
};

export type PlanOptions = {
  billing_type?: string;
  interval_count?: number;
};

const defaultOptions: PlanOptions = {
  billing_type: "recurring",
  interval_count: 30,
};

export type Plan = {
  plan_id: string;
  billing_type: string;
  currency: string;
  interval_count: number;
  amount: number;
  variant_contents?: string;
  variant_description?: string;
};

export const findDefaultPlan = (
  plans: Plan[],
  currency: Currency,
  options?: PlanOptions,
) => {
  const unifiedOptions = { ...defaultOptions, ...options };
  return plans.find(
    (p: any) =>
      p.billing_type === unifiedOptions.billing_type &&
      p.currency === currency.type.toLowerCase() &&
      p.interval_count === unifiedOptions.interval_count,
  );
};

export type ContentfulProduct = {
  ritualProduct: {
    sku: string;
    ritualPlans: Plan[];
    product_id: string;
    sub_category: string;
    url_slug: string;
  };
  slug: string;
  path: string;
  name: {
    name: string;
  };
  socialImage: {
    file: {
      url: string;
    };
  };
};

export type ProductAttributes = {
  id: string;
  category: string;
  variant: string;
  variant_contents: string;
  variant_description: string;
  sku: string;
  name: string;
  currency: string;
  price: number;
  quantity: number;
  slug: string;
  urlSlug: string;
  url: string;
  image_url: string;
  billing_type: string;
};

export const getProductAttributes = (
  product: ContentfulProduct,
  currency: Currency,
  quantity = 1,
) => {
  const { ritualProduct, slug, path, name, socialImage } = product;
  const { sku, ritualPlans, product_id, sub_category, url_slug } =
    ritualProduct;
  const plan = findDefaultPlan(ritualPlans, currency);
  const {
    plan_id,
    amount,
    variant_contents,
    variant_description,
    billing_type,
  } = plan as Plan;

  let productAttributes = {
    id: product_id,
    category: sub_category,
    variant: plan_id,
    variant_contents: variant_contents,
    variant_description: variant_description,
    sku: sku,
    name: NameMap(name).plain,
    currency: currency.type.toUpperCase(),
    price: amount / 100,
    quantity,
    slug: slug,
    urlSlug: url_slug,
    url: `${process.env.GATSBY_URL}${intlService.localizePath(`/${path}`)}`,
    image_url: `https:${socialImage.file.url}`,
    billing_type: billing_type,
  };
  return productAttributes as ProductAttributes;
};
